import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule
} from "vuex-module-decorators";
import API from "@/store/API/jwt";
import {
  userInfo,
  userCreate,
  userInRole,
  userDetail,
  changeUsername,
  changePassword,
  forgotPassword,
  resetPassword,
  Activate
} from "@/store/models/jwt";
import { setJWT, clearJWT } from "@/store/API/api";
import { eventHub } from "@/utils/eventHub";
import Vue from "vue";
import router from "@/router";
import i18n from "@/i18n";

const vm = new Vue();

@Module({ name: "JWTStore", dynamic: true, store })
class JWTModule extends VuexModule {
  authUser: any;
  isAuthenticated: any = localStorage.getItem("access_token") != null;
  acc: any = localStorage.getItem("access_token") || null;
  ref: any = "";
  regResponse: any = "";
  role: any = localStorage.getItem("role") || null;
  UserInRole: userInRole[] = [];
  count: number = 0;
  accessiblePlanList: number[] = [];

  User: userDetail = {} as userDetail;
  UserList: userDetail[] = [] as userDetail[];
  UserExists: boolean = false;
  LicenseNoExists: boolean = false;
  // Page: Page = {} as Page;

  @MutationAction
  async register(payload: userCreate) {
    var obj = (this.state as JWTModule).regResponse;
    obj = await API.register(payload);
    return { regResponse: obj as any };
  }

  @MutationAction
  async getJWT(payload: userInfo) {
    var obj = (this.state as JWTModule).acc;
    obj = await API.getJWT(payload);

    localStorage.setItem("refresh_token", obj.refresh);
    localStorage.setItem("access_token", obj.access);
    localStorage.setItem("role", obj.role);

    return { isAuthenticated: true as Boolean, role: obj.role as any };
  }

  @Action
  async getAccessToken() {
    const acc = await API.getAccessToken({
      refresh: localStorage.getItem("refresh_token")
    });
    localStorage.removeItem("access_token");
    localStorage.setItem("access_token", acc.access);
    setJWT();
    return { acc: acc.access as any };
  }

  get token() {
    return this.acc;
  }

  @Mutation
  setAuthUser(params: { authUser: string; isAuthenticated: Boolean }) {
    this.authUser = params.authUser;
    this.isAuthenticated = params.isAuthenticated;
  }

  get loggedIn() {
    // return localStorage.getItem("access_token") !== null;
    return this.isAuthenticated;
  }

  get userRole() {
    return localStorage.getItem("role");
  }

  get sqcc() {
    return localStorage.getItem("role") === "SQCCAdmin" || null;
  }

  @Mutation
  logOut() {
    this.acc = null;
    this.ref = null;
    this.role = null;
    this.isAuthenticated = false;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    clearJWT();
    eventHub.$emit("reloadPage");
    vm.$notify.warning(i18n.t("logoutMessage").toString());
    router.push({ name: "Home" }).catch(error => {});
  }

  @MutationAction
  async getUserInRole() {
    var list = (this.state as JWTModule).UserInRole;
    if (list.length === 0) {
      list = await API.getUserInRole();
    }
    return { UserInRole: list as userInRole[] };
  }

  @Mutation
  async deleteUserInRole(params: { userId: number; roleId: number; index: number }) {
    if (params.roleId !== 0 && params.userId !== 0) {
      await API.deleteUserInRole(params.userId, params.roleId);
    }
  }

  // @MutationAction
  // async searchUserInRole(params: {
  //   users: userInRole[];
  //   searchString?: any;
  //   page?: any;
  // }) {
  //   API.saveUserInRole(params.users);
  //   let query: string = "/?";
  //   if (params.searchString && params.searchString != undefined) {
  //     query += "&search=" + params.searchString;
  //   }
  //   if (params.page && params.page != undefined) {
  //     query += "&paging=true&page=" + params.page;
  //   }
  //
  //   const res = (await API.getUserInRole(query)) as Page;
  //   const list = res.results;
  //   // const list = await API.getUserInRole(query);
  //   const page = res as Page;
  //   return { UserInRole: list as userInRole[], Page: page };
  // }

  @Mutation
  pushUserInRole(userinrole: any) {
    this.UserInRole.push(userinrole);
  }

  @Mutation
  pushUserInRoleTemp(params: { index: number; object: userInRole }) {
    this.UserInRole[params.index].district = [];
    for (const i of params.object["district"]) {
      this.UserInRole[params.index].district.push(i);
    }
  }

  @Mutation
  updateUserInRole(params: { userinrole: userInRole; index: number }) {
    this.UserInRole.splice(params.index, 1, params.userinrole);
  }

  @MutationAction
  async saveUserInRole(userinrole: userInRole[]) {
    var obj = (this.state as JWTModule).UserInRole;
    obj = await API.saveUserInRole(userinrole);
    return { UserInRole: obj as userInRole[] };
  }

  @Action
  async changeUsername(username: changeUsername) {
    const chusr = await API.changeUsername(username);
  }

  @Action
  async changePassword(passwords: changePassword) {
    const chpass = await API.changePassword(passwords);
  }

  @Action
  async forgotPassword(email: forgotPassword) {
    return await API.forgotPassword(email);
  }

  @Action
  async resetPassword(data: resetPassword) {
    const conrepass = await API.resetPassword(data);
  }

  @Action
  async activate(data: Activate) {
    const activate = await API.activate(data);
  }

  @MutationAction
  async social_auth(data: any) {
    clearJWT();
    const obj = await API.social_auth(data);
    localStorage.setItem("refresh_token", obj.refresh);
    localStorage.setItem("access_token", obj.access);
    localStorage.setItem("role", obj.role);
    return { isAuthenticated: true as Boolean, role: obj.role as any };
  }

  @MutationAction
  async userexists(username: any) {
    const obj = await API.userexists({ username: username });
    return { UserExists: obj };
  }

  @MutationAction
  async licenseNoExists(seed_license_no: any) {
    const obj = await API.licenseNoExists({ seed_license_no: seed_license_no });
    return { LicenseNoExists: obj };
  }

  @Action
  async getUserReport(params: { company_role: string }): Promise<{ report: string }> {
    let query = `?company_role=${params.company_role}`;
    return await API.getUserReport(query);
  }
}

export default getModule(JWTModule);
