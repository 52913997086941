import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/cropVariety";
import {
  CropVariety,
  Crop,
  CropVariable,
  Data,
  CropVarietyFilter,
  SortableVariables,
  CropVarietyPaginated,
  CropVarietyComment,
  CropCategory,
  CropVarietyReply,
  CaptionFile,
  CropVarietySetting,
  SupplierCropVariety
} from "@/store/models/cropVariety";
import crop from "../API/crop";
import moment from "moment";

@Module({ name: "cropVarietyStore", dynamic: true, store })
class CropVarietyModule extends VuexModule {
  public CropName: Crop = {} as Crop;
  public Crop: Crop[] = [] as Crop[];
  public CropVariety: CropVariety = {} as CropVariety;
  public CropVarietyTemp: CropVariety = {} as CropVariety;
  public SimilarCropVarieties: CropVariety[] = [] as CropVariety[];
  public CropValue: CropVariety[] = [] as CropVariety[];
  public CropValuePaginated: CropVarietyPaginated = {} as CropVarietyPaginated;
  public CaptionFiles: CaptionFile[] = [] as CaptionFile[];
  public CropVarieties: CropVariety[] = [] as CropVariety[];
  public CropVarietiesPaginated: CropVarietyPaginated = {} as CropVarietyPaginated;
  public CropVarietyForAdmin: CropVariety[] = [] as CropVariety[];
  public ResponseCrop: CropVariety = {} as CropVariety;
  public CropVarietiesSave: CropVariable[] = [] as CropVariable[];
  public CropType: CropVariety[] = [] as CropVariety[];
  public CropVarietiesMetaVar: CropVariable[] = [] as CropVariable[];
  public CropVarietyVariableValue: CropVariety = {} as CropVariety;
  public CropVarietyFilterables: CropVarietyFilter[] = [] as CropVarietyFilter[];
  public Images: string[] = [];
  public ImageCaptions: string[] = [];
  public Variables: SortableVariables[] = [];
  public valueIndex: number = 0;
  public pageNumber: number = 1;
  public cropIdInList: number = 0;
  // public CropVarietyComment: CropVarietyComment[] = [];
  // public CropVarietyReply: CropVarietyReply[] = [];
  public cropCategory: CropCategory[] = [] as CropCategory[];
  public RecentCropVarieties: CropVariety[] = [] as CropVariety[];
  public Filter: any = {};
  public Sorter: string | null = null;
  public CropVarietySetting: CropVarietySetting = {} as CropVarietySetting;
  public SupplierCropVariety: SupplierCropVariety[] = [] as SupplierCropVariety[];

  get pageValues() {
    return this.CropValuePaginated;
  }

  @MutationAction
  async saveCrop(obj: any): Promise<any> {
    let cropname = await API.saveCrop(obj);
    return { ResponseCrop: cropname as CropVariety };
  }

  @Action
  async saveCropImage(obj: any): Promise<any> {
    let cropimage = await API.saveCropImage(obj);
  }

  @Mutation
  async saveCropCarousel(params: {
    id: number;
    carousel: boolean;
    carousel_order: number | null;
    index: number;
  }): Promise<any> {
    let objlist = this.CropVarietyForAdmin;
    await API.saveCropCarousel(params).then(
      res => {
        let variety = objlist[params.index];
        if (variety) {
          variety.carousel = params.carousel;
          variety.carousel_order = params.carousel_order;
        }
      },
      err => { }
    );
  }

  @MutationAction
  async saveCropVariable(params: { obj: any; index: any }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).CropVarietyForAdmin;
    let object = await API.saveCropVariable(params.obj);
    objlist.splice(params.index, 1, object);
    return { CropVarietyForAdmin: objlist as CropVariety[] };
  }

  @MutationAction
  async getAllCropVarietyForAdmin(language?: string) {
    const cropvarietyforadmin = await API.getAllCropvVarietyForAdmin(language);
    return { CropVarietyForAdmin: cropvarietyforadmin as CropVariety[] };
  }

  @MutationAction
  async getCropVarietyForAdminById(id: number) {
    let cropVariety = (this.state as CropVarietyModule).CropVariety;
    if (cropVariety == undefined) {
      cropVariety = await API.getCropVarietyForAdminById(id);
    } else if (cropVariety.croptypeid != id) {
      cropVariety = await API.getCropVarietyForAdminById(id);
    }
    return { CropVariety: cropVariety };
  }

  @MutationAction
  async getAllCropValue() {
    var cropValue = (this.state as CropVarietyModule).CropValue;
    if (cropValue.length == 0) {
      cropValue = await API.getAllCropValue();
    }
    return { CropValue: cropValue as CropVariety[] };
  }

  @MutationAction
  async getAllCropVariety() {
    let cropValue = await API.getAllCropVariety();
    return { CropVarieties: cropValue as CropVariety[] };
  }

  @MutationAction
  async saveCropVarietyName(params: { obj: any; index?: any }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).CropValue;
    let obj = await API.saveCropVarietyName(params.obj);
    // return { CropVarietyTemp: obj as CropVariety } ;
    if (params.index) {
      objlist.splice(params.index, 1, obj);
    } else {
      objlist.push(obj);
    }
    return { CropValue: objlist as CropVariety[] };
  }

  @MutationAction
  async getAllCropVarieties(params: { [key: string]: string }) {
    const cropVariety = await API.getAllCropVarieties(params);
    return { CropVarietiesPaginated: cropVariety as CropVarietyPaginated };
  }

  @MutationAction
  async getAllCropVarietiesList(lang: string = 'en') {
    let query = `?lang=${lang}`
    const cropVariety = await API.getAllCropVarietiesList(query);
    return { CropVarieties: cropVariety as CropVariety[] };
  }

  @MutationAction
  async getAllowedCropVarietiesByCropId(params: { id: number, lang?: string }) {
    let query = ''
    if (params.lang) {
      query += `?lang=${params.lang}`
    }
    const cropVariety = await API.getAllowedCropVarietiesByCropId(params.id, query);
    return { CropVarieties: cropVariety as CropVariety[] };
  }

  @MutationAction
  async getAllPaginatedCropValue() {
    var cropValuePaginated = (this.state as CropVarietyModule).CropValuePaginated;
    cropValuePaginated = await API.getAllCropValue();
    return { CropValuePaginated: cropValuePaginated as CropVarietyPaginated };
  }

  @MutationAction
  async getRecentCropVarieties(params: { lang?: string }) {
    let recentCropVarieties = await API.getRecentCropVarieties({ lang: params.lang });
    return { RecentCropVarieties: recentCropVarieties as CropVariety[] };
  }

  @MutationAction
  async getPaginatedCropValue(
    params: { search?: any; page?: number; language?: any } = {
      page: 1
    }
  ) {
    const CropValuePaginated = await API.getPaginatedCropValue({
      search: params.search,
      page: params.page,
      language: params.language
    });
    console.log(CropValuePaginated);
    return { CropValuePaginated: CropValuePaginated as CropVarietyPaginated };
  }

  @Mutation
  async getSearchedCropValue(searchInput: string) {
    const cropValue = await API.getSearchedCropValue(searchInput);
    // return { CropValue: cropValue as CropVariety[] };
    this.CropValue = cropValue;
  }

  @MutationAction
  async saveCropVarietyVariableValue(params: { obj: CropVariety; index?: any }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).CropValue;
    let obj = await API.saveCropVarietyVariableValue(params.obj);
    // return { CropVarietyTemp: obj as CropVariety } ;
    if (params.index) {
      objlist.splice(params.index, 1, obj);
    } else {
      objlist.push(obj);
    }
    return { CropValue: objlist as CropVariety[] };
  }

  // @MutationAction
  // async getAllCropVarieties(params: { [key: string]: string }) {
  //   const cropVariety = await API.getAllCropVarieties(params);
  //   return { CropVarieties: cropVariety as CropVariety[] };
  // }

  @MutationAction
  async getCropVarietyById(params: { id: any; language?: any }) {
    const cropVariety = await API.getCropVarietyById(params);
    return { CropVariety: cropVariety as CropVariety };
  }

  @MutationAction
  async saveCropVarietyImage(params: { obj: any; index?: any; rowindex?: any }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).CropVariety;
    var image = objlist.imagearray;
    let obj = await API.saveCropVarietyImage(params.obj);
    console.log(params.rowindex);
    if (image !== undefined && params.rowindex >= 0) {
      image.splice(params.rowindex, 1, obj);
    } else if (image !== undefined && params.rowindex === -1) {
      image.push(obj);
    } else {
    }
    return { CropVariety: objlist as CropVariety };
  }

  @MutationAction
  async deleteCropVarietyImage(params: {
    id: number;
    cropvarietiesid: number;
    rowindex: number;
  }): Promise<any> {
    console.log(params.rowindex);
    var objlist = (this.state as CropVarietyModule).CropVariety;
    var image = objlist.imagearray;
    if (image !== undefined) {
      image.splice(params.rowindex, 1);
    }
    await API.deleteCropVarietyImage({
      id: params.id,
      cropvarietiesid: params.cropvarietiesid
    });
    return { CropVariety: objlist as CropVariety };
  }

  @MutationAction
  async getAllCropVarietiesDetail(params: { [key: string]: string }) {
    const cropVariety = await API.getAllCropVarietiesDetail(params);
    return { CropVarietiesPaginated: cropVariety as CropVarietyPaginated };
  }

  @MutationAction
  async getCropVarietyDetailById(params: { id: number; lang?: string }) {
    const cropVariety = await API.getCropVarietyDetailById({
      cropId: params.id,
      lang: params.lang
    });
    return { CropVariety: cropVariety as CropVariety };
  }

  @MutationAction
  async getSimilarCropVariety(params: { cropId: number; lang?: string }) {
    const similarCropVariety = await API.getSimilarCropVariety(params);
    return { SimilarCropVarieties: similarCropVariety as CropVariety[] };
  }

  @MutationAction
  async deleteCropVariety(params: { id: number; index: number }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).CropValuePaginated;
    let obj = await API.deleteCropVariety(params.id);
    objlist["results"].splice(params.index, 1);
    return { CropValuePaginated: objlist as CropVarietyPaginated };
  }

  @MutationAction
  async getAllCropType() {
    const cropType = await API.getAllCropType();
    return { CropType: cropType as CropVariety[] };
  }

  @MutationAction
  async getAllCropVarietiesMetaVar() {
    const cropvarietiesmetavar = await API.getAllCropVarietiesMetaVar();
    return { CropVarietiesMetaVar: cropvarietiesmetavar as CropVariable[] };
  }

  @MutationAction
  async getImagesByVarietyId(variteyId: number) {
    const images = await API.getImagesByVarietyId(variteyId);
    return { Images: images["images"] as string[], ImageCaptions: images["captions"] as string[] };
  }

  @MutationAction
  async getSupplierCropVariety(params: any) {
    const supplier = await API.getSupplierCropVariety(params);
    return { SupplierCropVariety: supplier as SupplierCropVariety[] };
  }

  @MutationAction
  async saveCropVariety(obj: any): Promise<any> {
    var list = (this.state as CropVarietyModule).CropVarietiesSave;
    list = await API.saveCropVariety(obj);
    return { CropVarietiesSave: list as CropVariable[] };
  }

  // @Action
  // async updateCropVarietyValue(params:{
  //   aaaaaaaa : CropVariety;
  //   index:number;}) {
  //     console.log(params);
  //     this.CropVarieties.splice(params.index, params.aaaaaaaa);
  //     console.log(this.CropVarieties[params.index])
  // }

  @Action
  async updateCropVarietyMetaVar(params: { metavar: any[]; index: number }) {
    for (const i of params.metavar) {
      this.CropVarietiesMetaVar.splice(params.index, i);
    }
  }

  @MutationAction
  async getCropVarietyFilterables(params: { cropId: number; lang: string }): Promise<any> {
    var list = (this.state as CropVarietyModule).CropVarietyFilterables;
    list = await API.getCropVarietyFilterables(params.cropId, params.lang);
    for (let l of list) {
      if (l.querytype == "multicheckbox") {
        l.value = [];
      } else if (l.querytype == "range") {
        l.value = l.listofsearchvalues as number[];
      }
    }
    return { CropVarietyFilterables: list as CropVarietyFilter[] };
  }

  @MutationAction
  async getSortableVariables(params: { cropId: number; lang: string }) {
    const sortablevariables = await API.getSortableVariables(params.cropId, params.lang);
    return { Variables: sortablevariables as SortableVariables[] };
  }

  // @MutationAction
  // async getSortedCropVarieties(params: { cropId: number; sorter: string }) {
  //   const cropVariety = await API.getSortedCropVarieties(params);
  //   return { CropVarieties: cropVariety as CropVariety[] };
  // }

  @MutationAction
  async getSortedCropVarieties(params: { cropId: number; sorter: string }) {
    const cropVariety = await API.getSortedCropVarieties(params);
    return { CropVarietiesPaginated: cropVariety as CropVarietyPaginated };
  }

  @Mutation
  async setValueIndex(index: number) {
    this.valueIndex = index;
  }

  @Mutation
  async setPageNumber(page: number) {
    this.pageNumber = page;
  }

  @Mutation
  async setCropIdInList(id: number) {
    this.cropIdInList = id;
  }

  // @MutationAction
  // async getCropVarietyCommentsById(Id: number) {
  //   const cropvarietycomment = await API.getComment(Id);
  //   for (var i = 0; i < cropvarietycomment.length; i++) {
  //     cropvarietycomment[i].createdon = moment(new Date(cropvarietycomment[i].createdon)).fromNow();
  //   }
  //   return { CropVarietyComment: cropvarietycomment as CropVarietyComment[] };
  // }

  // @MutationAction
  // async saveComment(comment: CropVarietyComment) {
  //   const cropvarietycomment = await API.saveComment(comment);
  //   return { CropVarietyComment: cropvarietycomment as CropVarietyComment[] };
  // }

  @Mutation
  async setCategory(cropcategory: CropCategory[]) {
    this.cropCategory = cropcategory;
  }

  @Action
  async getCropCategory() {
    const cropcategory = await API.getCropCategory();
    this.setCategory(cropcategory);
  }

  // @MutationAction
  // async removeComment(id: number) {
  //   const cropvarietycomment = await API.removeComment(id);
  //   return { CropVarietyComment: cropvarietycomment as CropVarietyComment[] };
  // }

  // @Mutation
  // async saveReply(reply: CropVarietyReply) {
  //   const cropvarietyreply = await API.saveReply(reply);
  //   if (this.CropVarietyReply.filter(x => x.id === cropvarietyreply.id).length > 0) {
  //     this.CropVarietyReply.splice(
  //       this.CropVarietyReply.findIndex(x => x.id === cropvarietyreply.id),
  //       1,
  //       cropvarietyreply
  //     );
  //   } else {
  //     this.CropVarietyComment.filter(x => x.id == cropvarietyreply.commentid)[0].reply_count++;
  //     this.CropVarietyReply.push(cropvarietyreply);
  //   }
  // }

  // @Mutation
  // async setReply(replies: CropVarietyReply[]) {
  //   for (let reply of replies) {
  //     let index = this.CropVarietyReply.findIndex(x => x.id == reply.id);
  //     if (index > 0) {
  //       this.CropVarietyReply.splice(index, 1);
  //     }
  //     this.CropVarietyReply.push(reply);
  //   }
  // }

  // @Action
  // async getReplyByCommentId(commentId: number) {
  //   const replies = await API.getReply(commentId);
  //   this.setReply(replies);
  //   // return { CropVarietyReply: replies as CropVarietyReply[] };
  // }

  // @Mutation
  // async removeReply(id: number) {
  //   const response = await API.removeReply(id);
  //   let index = this.CropVarietyReply.findIndex(x => x.id == id);
  //   this.CropVarietyComment.filter(x => x.id == this.CropVarietyReply[index].commentid)[0]
  //     .reply_count--;
  //   this.CropVarietyReply.splice(index, 1);
  // }

  @Action
  async exportPdf(params: { id: number; lang?: string }) {
    return await API.exportPdf(params.id, params.lang);
  }

  @Mutation
  async saveFilterQuery(obj: any): Promise<any> {
    this.Filter = obj;
  }

  @Mutation
  async setSorter(obj: any): Promise<any> {
    this.Sorter = obj;
  }

  @MutationAction
  async getCropVariableSetting(params: { metavarid: number; croptypeid: number }) {
    let settings = await API.getCropVariableSetting({
      metavarid: params.metavarid,
      croptypeid: params.croptypeid
    });
    return { CropVarietySetting: settings as CropVarietySetting };
  }

  @MutationAction
  async saveCropVariableSetting(data: CropVarietySetting) {
    let settings = await API.saveCropVariableSetting(data);
    return { CropVarietySetting: settings as CropVarietySetting };
  }

  @Action
  async preRenderCropVariableDetail(params: { id: number; lang: string }) {
    return await API.preRenderCropVariableDetail(params);
  }
}

export default getModule(CropVarietyModule);
