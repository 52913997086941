import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/dsad";
import {
  DSADGroup,
  PaginatedDSADGroupList,
  Allocation,
  AllocationData,
  AdminAllocation,
  UserAllocationPagination,
  AdminAllocationPagination,
  DemandForecastReport,
  DistributionExcelReport,
  PaginatedDSADNoticeList
} from "@/store/models/dsad";

@Module({ name: "dsadGroupStore", dynamic: true, store })
class CropVarietyModule extends VuexModule {
  public DSADGroupList: PaginatedDSADGroupList = {} as PaginatedDSADGroupList;
  public DSADSupplyNoticeList: PaginatedDSADNoticeList = {} as PaginatedDSADNoticeList;
  public DSADDemandNoticeList: PaginatedDSADNoticeList = {} as PaginatedDSADNoticeList;
  public DSADGroup: DSADGroup = {} as DSADGroup;
  public Allocation: Allocation = {} as Allocation;
  public DistributionExcelReport: DistributionExcelReport = {} as DistributionExcelReport;
  public AdminAllocationList: AdminAllocationPagination = {} as AdminAllocationPagination;
  public UserAllocationList: UserAllocationPagination = {} as UserAllocationPagination;
  public UserDistributionList: UserAllocationPagination = {} as UserAllocationPagination;
  public DemandForecastReport: DemandForecastReport = {} as DemandForecastReport;

  @MutationAction
  async getAllDSADGroup(params: {
    search?: string;
    page?: number;
    lang?: string;
    role: string;
  }): Promise<any> {
    let query = "/";

    if (params.search || params.page || params.role) {
      query += "?";
    }
    if (params.search) {
      query += "&search=" + params.search;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.lang) {
      query += "&lang=" + params.lang;
    }
    query += "&role=" + params.role;

    let dsadGroupList = await API.getAllDSADGroup(query);
    return { DSADGroupList: dsadGroupList as PaginatedDSADGroupList };
  }

  @MutationAction
  async getDSADSupplyNotice(params: {
    search?: string;
    page?: number;
    lang?: string;
  }): Promise<any> {
    let query = "?notice=supply";
    if (params.search) {
      query += "&search=" + params.search;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.lang) {
      query += "&lang=" + params.lang;
    }

    let dsadGroupList = await API.getDSADNotice(query);
    return { DSADSupplyNoticeList: dsadGroupList as PaginatedDSADGroupList };
  }

  @MutationAction
  async getDSADDemandNotice(params: {
    search?: string;
    page?: number;
    lang?: string;
  }): Promise<any> {
    let query = "?notice=demand";
    if (params.search) {
      query += "&search=" + params.search;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.lang) {
      query += "&lang=" + params.lang;
    }

    let dsadGroupList = await API.getDSADNotice(query);
    return { DSADDemandNoticeList: dsadGroupList as PaginatedDSADGroupList };
  }

  @MutationAction
  async getDSADGroupById(id: number): Promise<any> {
    let dsadGroup = await API.getDSADGroupById(id);
    return { DSADGroup: dsadGroup as DSADGroup };
  }

  @MutationAction
  async saveDSADGroup(param: DSADGroup): Promise<any> {
    let dsadGroup = await API.saveDSADGroup(param);
    return { DSADGroup: dsadGroup as DSADGroup };
  }

  @Action
  async deleteDSADGroupById(id: number): Promise<any> {
    await API.deleteDSADGroupById(id);
  }

  @MutationAction
  async getAllocation(params: {
    fiscalYearId?: number;
    cropGroupId?: number;
    varietyId: number;
    seedTypeId: number;
    autoAllocate?: boolean;
    batchId?: number;
    lang?: string;
  }): Promise<any> {
    let query = "/?";
    // if (params.cropGroupId) {
    //   query += "&groupid=" + params.cropGroupId;
    // }
    if (params.varietyId) {
      query += "&cropvarietyid=" + params.varietyId;
    }
    if (params.fiscalYearId) {
      query += "&fiscalyearid=" + params.fiscalYearId;
    }
    if (params.seedTypeId) {
      query += "&seedtypeid=" + params.seedTypeId;
    }
    if (params.autoAllocate != undefined) {
      query += "&auto_allocate=" + params.autoAllocate;
    }
    if (params.lang != undefined) {
      query += "&lang=" + params.lang;
    }
    // if (params.batchId) {
    //   query += "&batchid=" + params.batchId;
    // }
    let allocation = await API.getAllocation(query);
    return { Allocation: allocation as Allocation };
  }

  @MutationAction
  async postAllocation(data: Allocation): Promise<any> {
    let allocation = await API.saveAllocation(data);
    return { Allocation: allocation as Allocation };
  }

  @MutationAction
  async getAdminAllocation(
    params?: {
      page?: number;
      fiscalyearid?: number;
      croptypeid?: number;
      cropvarietyid?: number;
      seedtypeid?: number;
      status?: string;
    },
    page: number = 1
  ): Promise<any> {
    let query = "";
    if (params) {
      query = "?";
      if (params.page) {
        query += "&page=" + params.page;
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + params.fiscalyearid;
      }
      if (params.croptypeid) {
        query += "&croptypeid=" + params.croptypeid;
      }
      if (params.cropvarietyid) {
        query += "&cropvarietyid=" + params.cropvarietyid;
      }
      if (params.seedtypeid) {
        query += "&seedtypeid=" + params.seedtypeid;
      }
      if (params.status) {
        query += "&status=" + params.status;
      }
    }
    let allocation = await API.getAdminAllocation(query);
    return { AdminAllocationList: allocation as AdminAllocation[] };
  }

  @MutationAction
  async getDemandForecastReport(
    params?: {
      page?: number;
      fiscalyearid?: number;
      croptypeid?: number;
      cropvarietyid?: number;
      seedtypeid?: number;
    },
    page: number = 1
  ): Promise<any> {
    let query = "";
    if (params) {
      query = "?";
      // if (params.page) {
      //   query += "&page=" + params.page;
      // }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + params.fiscalyearid;
      }
      if (params.croptypeid) {
        query += "&croptypeid=" + params.croptypeid;
      }
      if (params.cropvarietyid) {
        query += "&cropvarietyid=" + params.cropvarietyid;
      }
      if (params.seedtypeid) {
        query += "&seedtypeid=" + params.seedtypeid;
      }
    }
    let demandreportlink = await API.getDemandForecastReport(query);
    return { DemandForecastReport: demandreportlink as DemandForecastReport };
  }

  @Mutation
  async clearDemandForecastReport(): Promise<any> {
    this.DemandForecastReport = {} as DemandForecastReport;
  }

  @MutationAction
  async getUserAllocation(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    status?: string;
    search?: string;
    searchCode?: string;
    lang?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.search) {
        query += "&search=" + String(params.search);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + String(params.lang);
      }
    }
    let allocation = await API.getUserAllocation(query);
    return { UserAllocationList: allocation as UserAllocationPagination };
  }

  @MutationAction
  async downloadexcelreportdistribution(params: any): Promise<any> {
    let distributionexcelreport = await API.downloadexcelreportdistribution(params);
    return { DistributionExcelReport: distributionexcelreport as DistributionExcelReport };
  }

  async saveAllocationRootReceipt(params: {
    formData: any;
    allocationrootid: number;
  }): Promise<any> {
    var objlist = (this.state as CropVarietyModule).UserAllocationList;
    let receiptdata = await API.saveAllocationRootReceipt(params.formData);
    objlist.results.filter(x => x.id == params.allocationrootid)[0].image = receiptdata;
    return { UserAllocationList: objlist };
  }
}

export default getModule(CropVarietyModule);
