import { Module, VuexModule, MutationAction, getModule, Mutation } from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/crop";
import { Crop, CropCategory, CropName } from "@/store/models/crop";

@Module({ name: "cropStore", dynamic: true, store })
class CropModule extends VuexModule {
  public Crop: Crop = {} as Crop;
  public Crops: Crop[] = [] as Crop[];
  public CropNameList: CropName[] = [] as CropName[];
  public DSADCropList: CropName[] = [] as CropName[];
  public CropCategories: CropCategory = {} as CropCategory;

  @MutationAction
  async getAllCrops(params: { lang?: string }) {
    const crops = await API.getAllCrops(params);
    return { Crops: crops as Crop[] };
  }

  @Mutation
  clearCropNameList() {
    this.CropNameList = [];
  }

  @MutationAction
  async getAllCropName(params: { lang: string } = { lang: 'en' }) {
    let query = `?lang=${params.lang}`;
    const crops = await API.getAllCropName(query);
    return { CropNameList: crops as CropName[] };
  }

  @MutationAction
  async getDSADCropName(params: { phase: string; all?: boolean, lang?: string }) {
    let crops: any;
    let query = '?';
    if (params.all) {
      query += `&all=${params.all}`
    };
    if (params.phase) {
      query += `&phase=${params.phase}`
    };
    if (params.lang) {
      query += `&lang=${params.lang}`
    }
    crops = await API.getDSADCropName(query);

    return { DSADCropList: crops as CropName[] };
  }

  @MutationAction
  async getCropById(params: { id: number; lang?: string }) {
    const crop = await API.getCropById({ id: params.id, lang: params.lang });
    return { Crop: crop as Crop };
  }

  @MutationAction
  async getCropCategory(params: { lang?: string }) {
    const cropcategories = (await API.getCropCategoryNavBar(params)) as CropCategory;
    // const crop = crops.filter(x => x.Id === Id)[0];
    return { CropCategories: cropcategories as CropCategory };
  }
}

export default getModule(CropModule);
